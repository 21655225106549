'use client';

export default function GlobalError({ error, reset }) {
    return (
        <html>
            <body>
                <div className="text-center relative border border-solid p-5 h-screen mx-20 my-3 rounded-xl items-center">
                    <div className="rounded-xl w-full absolute inset-0 p-5">
                        <h1 className="font-bold text-9xl text-slate-400 mt-40 font-mono">500!</h1>
                        <h2 className="text-2xl font-extrabold font-mono mb-2 text-red-600">Internal Server Error!</h2>
                        <p className="font-medium my-1 text-2xl">Dont Worry.Its Not You, its us!</p>
                        <p className="font-medium my-1 text-2xl"> Please Refresh your Browser or Check back Soon!</p>
                    </div>
                </div>
            </body>
        </html>
    )
}